@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.auto-size-text {
	overflow-wrap: break-word;
	white-space: normal;
	word-break: break-word;
	display: inline-block;
	width: 100%;
	font-weight: 500;
	text-align: center;
	flex: 1; /* Permet au texte de remplir l'espace disponible */
	display: flex;
	align-items: center;
	justify-content: center;
}

.card-container {
	display: flex;
	flex-wrap: wrap;
}

.card {
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	padding: 1rem;
	overflow: hidden;
	max-width: 100%;
	width: 20%;
	width: 20%;
}

/*
	TRACK SONG
*/

.audio-player {
	width: 100%;
	max-width: 600px;
	margin: 0 auto;
	padding: 20px;
	background-color: #333;
	color: white;
	border-radius: 8px;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
.track-info {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
}
.left-section {
	display: flex;
	align-items: center;
}
.album-art {
	width: 60px;
	height: 60px;
	border-radius: 8px;
	margin-right: 10px;
}
.track-details {
	display: flex;
	flex-direction: column;
}
.track-title {
	font-size: 16px;
	margin: 0;
}
.track-artist {
	font-size: 14px;
	margin: 0;
	color: #aaa;
}
.play-button {
	z-index: 100;
	border: none;
	color: white;
	padding: 10px;
	border-radius: 50%;
	cursor: pointer;
	font-size: 20px;
	margin-left: 20px;
	transition: background-color 0.3s ease, transform 0.2s ease,
		box-shadow 0.2s ease;
}

.play-button:hover {
	transform: scale(1.1);
}

.play-button:active {
	transform: scale(0.95);
}

.play-button:focus {
	outline: none;
}
.controls {
	display: flex;
	justify-content: center;
	margin-bottom: 10px;
}
button {
	border: none;
	color: white;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
	font-size: 16px;
}
.time-controls {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 10px;
}
.progress-bar-container {
	flex: 1;
	height: 10px;
	background-color: #444;
	border-radius: 5px;
	cursor: pointer;
	margin: 0 10px;
}
.progress-bar {
	height: 100%;
	background-color: #1db954;
	border-radius: 5px;
	transition: width 0.1s ease;
}
